/* pathway-gothic-one-400normal - latin */
@font-face {
  font-family: 'Pathway Gothic One';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    local('Pathway Gothic One Regular '),
    local('Pathway Gothic One-Regular'),
    url(/static/media/pathway-gothic-one-latin-400.967ca270.woff2) format('woff2'), 
    url(/static/media/pathway-gothic-one-latin-400.d45cc473.woff) format('woff'); /* Modern Browsers */
}


